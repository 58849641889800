<script>
import EventForm from "@/views/menu/event/eventForm";
import Component, { mixins } from "vue-class-component";
import _env from "@/plugins/env";

@Component({
  inject: {
    eventService: "eventService"
  }
})
export default class EventDetail extends mixins(EventForm) {
  formId = "detail-event-form";

  get decoratedElements() {
    return this.insertAfter(
      this.allReadonlyFields,
      {
      id: "eventLink",
      label: this.translations.labels.event_form_event_link,
      type: "text",
      readonly: true,
      copyToClipboard: true,
    },
      {
        id: "isEnabled",
        label: this.translations.labels.table_enable,
        type: "switch",
        readonly: true
      },
      "publishDate"
    );
  }

  get fieldColumns() {
    return 2;
  }

  getFormTitle () {
    return this.translations.pageTitles.events_detail;
  }

    generateEventLink(val) {
    this.model.eventLink = val;
  }

  async created () {
    this.model = await this.eventService.read(this.$route.params.id);
    this.model.eventLink = "Not yet generated";

    this.model.languageIds = [];
    for (let i in this.model.languages) {
      const { id, isDefault } = this.model.languages[i];
      if (isDefault) {
        this.model.defaultLanguageId = id;
      }
      this.model.languageIds.push(id);
    }
    this.generateEventLink(`${_env("VUE_APP_VIRTUAL_PLACE_URL")}/event-${this.model.code}`);

    this.isReady = true;
  }
}
</script>
